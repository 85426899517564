<template>
  <v-container>
    <v-row justify="center">
      <v-icon class="select-date-back" @click="updateMonth(-1)">arrow_back_ios</v-icon>

      <v-menu open-on-hover offset-y>
        <template v-slot:activator="{ on, attrs }">
          <label :style="`font-size: 24px; color: ${variables.colorPrimary};`" v-bind="attrs" v-on="on">{{item.text}}</label>
        </template>

        <v-list>
          <v-list-item
            v-for="(option, index) in optionList"
            :key="index"
            link>
            <v-list-item-title class="text-center" @click="onSelectOption(option)">{{option.text}}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-icon class="select-date-next" @click="updateMonth(1)">arrow_forward_ios</v-icon>
    </v-row>
  </v-container>
</template>

<script>
import Events from '@/core/service/events'
import variables from '@/assets/styles/helpers/_variables.scss'
import moment from 'moment'

export default {
  name: 'Calendar',
  props: {},
  directives: {},
  components: {},

  data: () => ({
    item: {
      month: moment().month(),
      year: moment().year(),
      week: moment().week(),
      type: 'month',
      text: moment().format('MMMM [de] YYYY')
    },
    optionList: [{ id: 'current', text: 'ATUAL' }]
  }),

  created () {},

  mounted () {},

  computed: { variables: () => variables },

  watch: {},

  methods: {
    updateMonth (val) {
      const self = this
      self.item.month += val

      if (self.item.month > 11) {
        self.item.year += val
        self.item.month = 0
      }

      if (self.item.month < 0) {
        self.item.year += val
        self.item.month = 11
      }

      self.item.text = moment().month(self.item.month).format(`MMMM [de] ${self.item.year}`)
      Events.$emit('calendar::sendData', self.item)
    },

    onSelectOption (option) {
      const self = this
      const observer = {
        current: () => {
          self.item = {
            month: moment().month(),
            year: moment().year(),
            week: moment().week(),
            type: 'month',
            text: moment().format('MMMM [de] YYYY')
          }
        }
      }

      observer[option.id]()
      Events.$emit('calendar::sendData', self.item)
    }
  },

  validations () { return {} }
}
</script>

<style lang="scss" scoped>
</style>